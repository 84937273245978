





































































































import { Component, Vue } from 'vue-property-decorator'
import TipModal from '@/views/common/tip-modal.vue'
import EditModal from './edit-modal.vue'
import { Avatar } from 'element-ui'
Vue.use(Avatar)

@Component({
  components: {
    TipModal,
    EditModal
  }
})
export default class extends Vue {
  loading = false
  editLoading = false
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  carouseLists = []
  // 删除编辑的轮播图id
  carouselId = ''
  // 编辑的轮播图内容
  carouseInfo = {}
  // 新增还是编辑
  isEditOrAdd = 'edit'
  openWhichModal = ''
  hoverPicId = -1
  lastOne = false

  created() {
    this.getCarouselList()
  }
  getCarouselList() {
    this.loading = true
    this.$api.property
      .getCarouselList()
      .then((res: any) => {
        this.loading = false
        if (res.data && res.data.success) {
          this.carouseLists = res.data.data
          if (res.data.total === 1) {
            this.lastOne = true
          } else {
            this.lastOne = false
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  saveEdit(item: any) {
    if (this.editLoading) {
      return
    }
    this.editLoading = true
    if (this.isEditOrAdd === 'add') {
      this.$api.property
        .addCarouse({
          ...item
        })
        .then((res: any) => {
          this.editLoading = false
          if (res.data && res.data.success) {
            this.$message.success(res.data.msg)
            this.getCarouselList()
            this.closeDialog()
          }
        })
        .catch(() => {
          this.editLoading = false
        })
    } else if (this.isEditOrAdd === 'edit') {
      this.$api.property
        .editCarouse({
          id: this.carouselId,
          ...item
        })
        .then((res: any) => {
          this.editLoading = false
          if (res.data && res.data.success) {
            this.$message.success(res.data.msg)
            this.getCarouselList()
            this.closeDialog()
          }
        })
        .catch(() => {
          this.editLoading = false
        })
    }
  }

  mouseOver(index: number) {
    this.hoverPicId = index
  }

  mouseLeave() {
    this.hoverPicId = -1
  }

  // 关闭对话框
  closeDialog() {
    this.openWhichModal = ''
  }
  //删除
  deleteEdit() {
    if (this.carouselId) {
      this.deleteCarosel()
    } else {
      this.openWhichModal = ''
      this.isEditOrAdd = ''
    }
  }
  deleteCarosel() {
    this.loading = true
    this.$api.property
      .deleteCarouse(this.carouselId)
      .then((res: any) => {
        this.loading = false
        if (res.data && res.data.success) {
          this.$message.success(res.data.msg)
          this.getCarouselList()
          this.closeDialog()
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  doDelete() {
    this.openWhichModal = 'delete'
  }
  goEdit(item: any) {
    const cur = this.carouseLists.find((x: any) => x.id == this.carouselId)
    const items = cur || item
    if (items) {
      const info = JSON.stringify(items)
      this.$router.push({
        path: '/property/carousel-edit',
        query: {
          isEditType: this.openWhichModal,
          id: this.carouselId || '',
          info: info
        }
      })
    }
  }
  doEdit(type: any, item: any) {
    if (type == 'add' && this.carouseLists.length >= 5) {
      this.$message.warning('最多添加5张轮播图')
      return
    }
    this.carouseInfo = {}
    this.openWhichModal = type
    this.isEditOrAdd = type
    if (item) {
      this.carouselId = item.id
      this.carouseInfo = item
      this.$router.push({
        path: '/property/carousel-edit',
        query: {
          isEditType: type,
          id: item.id
        }
      })
    } else {
      // if (this.carouseLists.length < 5) {
      //   this.openWhichModal = 'edit'
      // } else {
      //   this.$message.warning('最多添加5张轮播图')
      // }
    }
  }
}
