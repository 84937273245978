









































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'

import { Upload } from 'element-ui'
Vue.use(Upload)

@Component({
  components: {}
})
export default class extends Vue {
  $constants: any
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean
  @Prop({ default: 'edit' }) private isEditOrAdd!: string
  @PropSync('title', { type: String, default: '' }) syncTitle!: any
  @PropSync('url', { type: String, default: '' }) syncUrl!: any
  @PropSync('imageUrl', { type: String, default: '' }) syncImageUrl!: any
  @PropSync('carouselId', { type: Number }) syncCarouselId!: any
  @PropSync('lastOne', { type: Boolean }) syncLastOne!: any

  showSyncImageUrl = ''

  handleAvatarSuccess(res: any) {
    this.syncImageUrl = res.data
  }
  beforeAvatarUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    const isPNG = file.type === 'image/png'
    const isLt5M = file.size / 1024 / 1024 < 5

    if (!isJPG && !isJPEG && !isPNG) {
      this.$message.error('上传照片只能是 JPG/JPEG/PNG 格式!')
    }
    if (!isLt5M) {
      this.$message.error('上传头像图片大小不能超过 5MB!')
    }
    return (isJPG || isJPEG || isPNG) && isLt5M
  }
  handleAvatarChange(file: any) {
    this.showSyncImageUrl = URL.createObjectURL(file.raw)
  }
  deleteTemp() {
    this.$emit('optDelete', '')
  }
  goEdit() {
    if (!this.syncImageUrl) {
      this.$message.warning('请选择图片')
      return
    }
    this.$emit('optEdit', {
      title: this.syncTitle,
      pic: this.syncImageUrl
    })
  }
  dialogConfirm() {
    if (this.syncImageUrl === '') {
      this.$message.warning('请选择图片')
    } else {
      this.$emit('optSuccess', {
        title: this.syncTitle,
        pic: this.syncImageUrl
      })
    }
  }
}
